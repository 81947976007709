body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.share-propty {
  display: flex;
  align-items: center;
  margin-top: 20px;
}
.share-propty h2 {
  margin: 0;
}
.share-propty span {
  background: #13497b;
  padding: 0 10px;
  border-radius: 35px;
  font-size: 14px;
  color: #fff;
  margin-left: 20px;
  cursor: pointer;
}
