* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -o-box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

html,
body,
address,
blockquote,
div,
dl,
form,
h1,
h2,
h3,
h4,
h5,
h6,
ol,
p,
pre,
table,
ul,
dd,
dt,
li,
tbody,
td,
tfoot,
th,
thead,
tr,
button,
del,
ins,
map,
object,
a,
abbr,
acronym,
b,
bdo,
big,
br,
cite,
code,
dfn,
em,
i,
img,
kbd,
q,
samp,
small,
span,
strong,
sub,
sup,
tt,
var,
legend,
fieldset,
p,
aside,
header,
footer,
section,
article {
  border: medium none;
  margin: 0;
  padding: 0;
}

@font-face {
  font-family: "DM Sans";
  src: url("../font/DMSans-Italic.eot");
  src: url("../font/DMSans-Italic.eot?#iefix") format("embedded-opentype"),
    url("../font/DMSans-Italic.woff2") format("woff2"),
    url("../font/DMSans-Italic.woff") format("woff"),
    url("../font/DMSans-Italic.ttf") format("truetype"),
    url("../font/DMSans-Italic.svg#DMSans-Italic") format("svg");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "DM Sans";
  src: url("../font/DMSans-Bold.eot");
  src: url("../font/DMSans-Bold.eot?#iefix") format("embedded-opentype"),
    url("../font/DMSans-Bold.woff2") format("woff2"),
    url("../font/DMSans-Bold.woff") format("woff"),
    url("../font/DMSans-Bold.ttf") format("truetype"),
    url("../font/DMSans-Bold.svg#DMSans-Bold") format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "DM Sans";
  src: url("../font/DMSans-BoldItalic.eot");
  src: url("../font/DMSans-BoldItalic.eot?#iefix") format("embedded-opentype"),
    url("../font/DMSans-BoldItalic.woff2") format("woff2"),
    url("../font/DMSans-BoldItalic.woff") format("woff"),
    url("../font/DMSans-BoldItalic.ttf") format("truetype"),
    url("../font/DMSans-BoldItalic.svg#DMSans-BoldItalic") format("svg");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "DM Sans";
  src: url("../font/DMSans-MediumItalic.eot");
  src: url("../font/DMSans-MediumItalic.eot?#iefix") format("embedded-opentype"),
    url("../font/DMSans-MediumItalic.woff2") format("woff2"),
    url("../font/DMSans-MediumItalic.woff") format("woff"),
    url("../font/DMSans-MediumItalic.ttf") format("truetype"),
    url("../font/DMSans-MediumItalic.svg#DMSans-MediumItalic") format("svg");
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "DM Sans";
  src: url("../font/DMSans-Regular.eot");
  src: url("../font/DMSans-Regular.eot?#iefix") format("embedded-opentype"),
    url("../font/DMSans-Regular.woff2") format("woff2"),
    url("../font/DMSans-Regular.woff") format("woff"),
    url("../font/DMSans-Regular.ttf") format("truetype"),
    url("../font/DMSans-Regular.svg#DMSans-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "DM Sans";
  src: url("../font/DMSans-Medium.eot");
  src: url("../font/DMSans-Medium.eot?#iefix") format("embedded-opentype"),
    url("../font/DMSans-Medium.woff2") format("woff2"),
    url("../font/DMSans-Medium.woff") format("woff"),
    url("../font/DMSans-Medium.ttf") format("truetype"),
    url("../font/DMSans-Medium.svg#DMSans-Medium") format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

:focus {
  outline: none !important;
}

h1 {
  font-size: 36px;
  color: #13497b;
  font-size: 45px;
  font-family: "DM Sans";
}

h2 {
  font-size: 30px;
  color: #13497b;
  font-size: 40px;
  font-family: "DM Sans";
}

h3 {
  font-size: 30px;
  font-family: "DM Sans";
}

h4 {
  font-size: 18px;
  font-family: "DM Sans";
}

img {
  max-width: 100%;
  font-family: "DM Sans";
}

img,
fieldset {
  border: 0 none;
}

p {
  color: #8e8e8e;
  font-family: Poppins;
  font-size: 16px;
  line-height: 26px;
}

.basic-payment h3 {
  font-size: 16px;
  font-weight: 400;
  display: flex;
  align-items: center;
}

input[type="submit"],
select {
  -webkit-appearance: none;
  font-family: Poppins;
}

img {
  border: none;
  height: auto;
  max-width: 100%;
  outline: none;
  vertical-align: middle;
}

iframe {
  vertical-align: middle;
}

a:hover {
  text-decoration: none;
  color: #002395;
}

.clear {
  clear: both;
}

ul::after {
  clear: both;
  content: "";
  display: block;
}

ul {
  list-style: none;
  font-family: Poppins;
}

.poppins {
  font-family: Poppins;
}

.btn.sign-btn:focus,
.social-btn .btn:focus,
.btn.okbtn:focus {
  box-shadow: none;
}

.login-area .form-control {
  background: #ffffff;
  border: 1px solid #dadada;
  border-radius: 5px;
  font-size: 15px;
  padding: 13px;
}

.info-details {
  position: relative;
}

.login-area .form-control:focus {
  outline: none;
  box-shadow: none;
  border-color: #dadada;
}

.form-label {
  color: #1d1d1d;
  font-size: 16px;
}

.form-select:focus,
.form-control:focus {
  border-color: #dadada;
  outline: 0;
  box-shadow: none;
}

body {
  margin: 0;
  padding: 0;
  font-size: 16px;
  line-height: 27px;
  color: #303030;
  -webkit-font-smoothing: antialiased;
  font-family: "Poppins", sans-serif;
}

header {
  background: #fff;
  box-shadow: 0px 21px 33px rgba(0, 0, 0, 0.05);
  padding: 13px 40px;
}

header .navbar-light .navbar-nav .nav-link {
  color: #1d1d1d;
  text-transform: uppercase;
  font-size: 15px;
  font-weight: 500;
  margin: 0 8px;
  position: relative;
  padding: 0;
}

header .navbar-light .navbar-nav .nav-link:hover {
  color: #13497b;
}

.btn.fill-btn {
  border: 1.5px solid #13497b;
  background: #13497b !important;
  box-shadow: 0px 4px 14px 5px rgba(19, 73, 123, 0.1);
  border-radius: 5px;
  color: #fff;
  padding: 8px 20px;
  text-transform: uppercase;
  margin-left: 13px;
  font-size: 16px;
}

.btn.fill-btn:hover {
  background-color: #022b51;
}

.btn.outline-btn {
  border: 1.5px solid #13497b;
  background: transparent !important;
  color: #13497b !important;
  box-shadow: none !important;
  padding: 8px 16px;
}

.login-area {
  background: url(../images/login-bg.png) no-repeat;
  background-size: cover;
  width: 100%;
  position: relative;
  min-height: calc(100vh - 12px);
}

.login-box {
  border: 1px solid rgba(29, 29, 29, 0.1);
  box-shadow: 0px 21px 33px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  background-color: #fff;
}

.login-form {
  padding: 30px 40px 0 40px;
}

.login-box-area {
  width: 100%;
  display: flex;
  padding: 125px 0;
  flex-direction: column;
  justify-content: center;
}

.btn.sign-btn {
  background: #11dfed;
  box-shadow: 0px 4px 14px 5px rgb(17 223 237 / 10%);
  border: 0;
  padding: 13px;
  font-size: 18px;
  font-weight: 500;
}

.login-form h2 {
  font-size: 40px;
  color: #13497b;
  font-size: 40px;
  font-family: "DM Sans";
  font-weight: bold;
}

.btn.sign-btn:hover {
  background-color: #0bcfdd;
}

.social-btn .btn {
  border: 1px solid #eaeaea;
  border-radius: 11px;
  padding: 12px;
  font-size: 14px;
  font-weight: 500;
  line-height: 2;
  width: 49%;
  position: relative;
}

.bid-now-btn button {
  background: #49e0ed;
  color: #fff;
  width: 75%;
  padding: 12px 0;
  text-transform: uppercase;
  border-radius: 5px;
  font-size: 18px;
  font-weight: 400;
  margin-top: 10px;
}

.user-image img {
  width: 40px;
  max-width: 40px;
  object-fit: contain;
  height: 40px;
}

.nav-user-profile {
  display: flex;
  align-items: center;
  margin-left: 35px;
}

a.bottom-border.dropdown-item {
  border-bottom: unset !important;
}

.social-btn .btn img {
  margin-right: 10px;
}

.user-info button#dropdown-basic {
  background: none;
  border: none;
  color: #000;
  font-size: 16px;
  font-weight: 500;
}

.user-info .dropdown-menu.show a i {
  margin-right: 10px;
}

.dropdown-menu.show a {
  border-bottom: 1px solid #ededed;
  padding: 10px;
}

p.forgot,
.signup {
  outline: none;
  color: #13497b;
  font-weight: 500;
  text-decoration: underline;
  cursor: pointer;
}

.table-section td {
  color: #13497b;
  font-weight: 700;
}

.table-section th {
  text-align: center;
}

.footer-color {
  background: url(../images/footer-bg.png) no-repeat;
  background-size: cover;
  width: 100%;
  position: relative;
  padding: 0 0 10px;
}

td.bg-color {
  text-align: center;
  background: #11dfed !important;
}

.about-table-section {
  padding-top: 50px;
}

footer {
  background: #1d1d1d;
}

.footer-form {
  position: relative;
}

.footer-form input {
  padding: 13px;
  padding-right: 205px;
}

.footer-form input:focus {
  box-shadow: none;
}

.footer-form .btn.sign-btn {
  font-size: 17px;
  padding: 13px 40px;
}

.footer-form .btn.fill-btn {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  right: 0;
  margin: 0;
  text-transform: none;
}

.footer-form .btn.fill-btn:hover {
  background-color: #0bcfdd;
}

.footer-area h3 {
  font-size: 30px;
  font-family: "DM Sans";
  font-weight: bold;
}

footer ul li {
  display: inline-block;
  color: #fff;
  margin-left: 23px;
  text-transform: uppercase;
  font-size: 16px;
}

footer p {
  color: #fff;
  text-decoration: none;
  font-weight: 300;
}

.text-right {
  text-align: right;
}

.copyright-area ul li {
  text-transform: capitalize;
  font-size: 14px;
}

.copyright-area {
  padding: 19px 0;
  border-top: 1px solid #303031;
}

.footer-color ul {
  display: flex;
  justify-content: center;
}

.upload-profile {
  width: 140px;
  height: 140px;
  position: relative;
  border-radius: 100%;
  margin: 0 auto 5px;
  overflow: hidden;
  background: #ffffff;
  border: 1px solid #e3e3e3;
  box-sizing: border-box;
}

.upload-profile input {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  z-index: 99999;
  border-radius: 100%;
}

.upload-profile:after {
  content: "\f03e";
  font-family: "FontAwesome";
  position: absolute;
  top: 42%;
  left: 40%;
  font-size: 30px;
  color: #474646;
  opacity: 0.2;
}

.property {
  display: none;
}

.btn.fill-btn.margin-left {
  margin-left: 60px;
}

.navbar-brand img {
  width: 100px;
}

.copyright-area li p {
  cursor: pointer;
}

.copyright-area li p:hover,
footer ul li p:hover {
  color: #11dfed;
  cursor: pointer;
}

.css-tlfecz-indicatorContainer,
.css-1gtu0rj-indicatorContainer {
  padding: 0 !important;
}

.flex-p {
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-area.forgot-area,
.login-area.otp-area {
  min-height: calc(100vh - 130px);
}

.otp_text span {
  color: #11dfed;
  font-weight: 500;
  font-size: 16px;
}

.otp-area .otp {
  justify-content: space-between;
}

.otp-area .otp input {
  background: #ffffff;
  border: 1px solid #dadada;
  border-radius: 5px;
  padding: 13px;
  width: 98px !important;
}

.modal .modal-content {
  border: 0;
  border-radius: 5px;
}

.btn.okbtn {
  background: #11dfed;
  box-shadow: 0px 4px 14px 5px rgba(17, 223, 237, 0.1);
  border-radius: 5px;
  text-transform: uppercase;
  color: #ffffff;
  font-weight: 500;
  font-size: 18px;
  padding: 13px 35px;
}

.btn.okbtn:hover {
  background-color: #0bcfdd;
}

.otp_popup_verify h5 {
  font-weight: 600;
  font-size: 18px;
  color: #1d1d1d;
}

.heading-area-top h2 {
  color: #13497b;
  font-size: 40px;
  font-family: "DM Sans";
  font-weight: bold;
}

.heading-area-top {
  margin-top: 100px;
}

.basic-payment {
  /* display: flex; */
  justify-content: space-between;
  align-items: center;
}

.plans-description li {
  color: #1d1d1d;
  line-height: 36px;
}

.plans-description img {
  margin-right: 10px;
}

.choose-plan-bt {
  background: #11dfed !important;
  width: 100%;
  color: #fff !important;
  text-transform: uppercase;
}

.plans-description {
  padding: 0;
}

.plans-description li {
  color: #1d1d1d;
  display: flex;
  align-items: baseline;
  margin-bottom: 24px;
  line-height: 24px;
  font-size: 16px;
}

.basic-plan-box {
  padding: 30px;
  background: white;
  border-radius: revert;
  box-shadow: 0px 15px 8px #00000017;
  border-image-source: linear-gradient(to top, #22222200, #13497b);
  border-image-slice: 1;
  border-width: 2px;
  border-style: solid;
  box-sizing: border-box;
  max-width: 347px;
  margin: 0 auto;
  position: relative;
  height: fit-content;
  cursor: pointer;
}

.date-time-section {
  display: flex;
  justify-content: space-between;
}

.time-section p {
  color: #000;
}

.basic-payment h3 b {
  font-size: 30px;
  font-weight: 600;
  color: #13497b;
  font-family: "DM Sans";
}

.basic-payment h3.subscription-plan-price b {
  text-decoration: none !important;
}

.basic-payment h3 b {
  font-size: 34px;
  font-weight: 600;
  color: #13497b;
  font-family: "DM Sans";
  margin: 0 5px;
  text-decoration: line-through;
}

.special-pricing p {
  font-size: 12px;
  margin-bottom: 0;
  color: #000000;
  font-weight: 300;
}

.special-pricing h3 b {
  text-decoration: none;
}

.special-pricing h3 {
  margin-bottom: 0;
}

.special-pricing p span {
  color: #11dfed;
  font-weight: 500;
}

.special-pricing {
  margin-top: -10px;
}

.next-step {
  background: #13497b !important;
  text-transform: uppercase;
  color: #fff !important;
  width: 210px;
  height: 45px;
  margin: 0 5px;
}

.skip-now {
  border: 1px solid #13497b !important;
  text-transform: uppercase;
  color: #13497b !important;
  width: 210px;
  height: 45px;
  margin: 0 5px;
}

.next-skip-bt {
  text-align: center;
  margin-top: 70px;
}

.btn:focus {
  box-shadow: none !important;
}

.hero-banner {
  background-image: url("../images/property_banner.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 60px 0;
}

.hero-banner.favorite-banner {
  background-image: url("../images/property_banner2.png");
}

.about-bg {
  background-image: url("../images/about-bg.png");
}

.property-banner-heading {
  font-size: 18px;
  text-transform: uppercase;
  color: #fff;
  font-weight: 400 !important;
}

.property-banner-heading img {
  margin-right: 5px;
}

.hero-banner h2 {
  font-size: 40px;
  font-weight: 500;
  margin: 10px 0;
  color: #fff;
}

.hero-banner p {
  color: #fff;
  font-weight: 300;
}

.listing-btn:hover {
  background-color: #13497b !important;
  color: #fff !important;
}

.next-previous-tab p {
  margin-bottom: 0;
  display: flex;
  align-items: center;
}

.blue-text {
  font-weight: 500;
  color: #13497b;
  cursor: pointer;
}

.properties-slider li img {
  width: 100%;
  height: 350px;
  object-fit: cover;
}

.next-previous-tab {
  margin: 15px 0;
}

.next-previous-tab i {
  margin: 0 10px;
}

.properties-slider li {
  padding: 0 3px;
}

.upload-img p {
  margin-top: 11px;
}

.react-multiple-carousel__arrow {
  background: rgb(255 255 255) !important;
}

.react-multiple-carousel__arrow--right::before {
  content: "\21FE" !important;
  color: #000 !important;
}

.react-multiple-carousel__arrow--left::before {
  content: "\21FD" !important;
  color: #000 !important;
}

.service-property {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

td.text-spacing {
  padding-top: 22px !important;
  font-size: 20px;
}

.service-property {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.service-property.service-property-two {
  margin-bottom: 35px;
}

.plan-text-picture {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.service-property {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px 0;
}

.person-profile-image img {
  object-fit: cover;
  width: 150px;
  height: 150px;
}

.person-profile-image {
  width: 150px;
  height: 150px;
  overflow: hidden;
  border-radius: 50%;
  margin: 0 auto;
  margin-bottom: 15px;
}

.information-table.row {
  margin-top: 15px;
}

.service-one h6 {
  font-size: 18px;
}

.service-one img {
  margin-right: 15px;
}

.service-one {
  display: flex;
  align-items: center;
}

.service-one p {
  text-transform: uppercase;
  color: #000;
  margin-bottom: 0;
}

.service-one h6 {
  margin-bottom: 0;
  font-weight: 600;
}

.map-side {
  height: calc(100vh - 22px);
  position: sticky;
  top: 12px;
}

.map-side #map {
  height: 100%;
}

/* .map-proprty-area .row{
  width: 100%;
} */
.user-info a.dropdown-item {
  padding: 0 !important;
}

.user-info .dropdown-menu.show {
  padding: 0;
  border-bottom: none;
}

.user-info .dropdown-menu.show a {
  background: white !important;
}

.user_update-profile input {
  position: absolute;
  height: 150px;
  top: 10px;
  z-index: 10;
  opacity: 0;
}

.area-bid-one {
  width: -webkit-fill-available;
}

.user-image {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  overflow: hidden;
}

.user-image img {
  min-width: 100%;
  height: 100%;
  object-fit: cover;
}

.person-profile {
  text-align: center;
  border: 1px solid rgba(29, 29, 29, 0.1);
  border-radius: 5px;
  padding: 30px;
}

.three-radio-input {
  border-top: 1px solid #00000026;
  border-bottom: 1px solid #00000026;
}

.house-details {
  margin-top: 40px;
  position: relative;
}

.base-price-top {
  text-align: center;
  border: 1px solid #e8e8e8;
  border-radius: 5px;
  padding: 16px 102px !important;
  margin-top: 40px;
  position: absolute;
  top: 0;
  right: 60px;
}

.auction-box-top {
  position: absolute;
  top: 0;
  right: 60px;
}

.feature-box {
  justify-content: space-between;
  margin-top: 40px;
}

.feature-box a {
  color: #11dfed;
  font-weight: 500;
}

.house-details h6 {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 0;
  margin-top: 5px;
}

.social-icons-box a {
  margin: 0 5px;
}

.information-table tr td:first-child {
  color: rgba(29, 29, 29, 0.5);
}

.agent-profile-div {
  border: 1px solid rgba(29, 29, 29, 0.1);
  border-radius: 5px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 113px;
}

.agent-profile-div img {
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 100%;
}

.base-price-box {
  text-align: center;
  border: 1px solid #e8e8e8;
  padding: 20px 0;
  border-radius: 5px;
  margin-top: 40px;
}

.base-price-box h5 {
  font-size: 30px;
  font-weight: 700;
  color: #11dfed;
}

.base-price-box h5 span {
  font-size: 18px;
}

.agent-profile-div p {
  color: #000;
  margin: 5px 0 0 0;
  font-size: 15px;
  width: 85%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.bottom-banner {
  background-image: url("../images/bt-banner.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 50px 0;
  margin-top: 40px;
}

.bottom-banner a {
  color: #fff;
}

.house-details-left-side {
  padding-right: 70px;
}

.bottom-banner i {
  font-size: 10px;
  margin-left: 7px;
}

.modal-title.h4 {
  font-size: 20px;
  color: #13497b;
  font-weight: 600;
}

.details-modal .modal-header {
  border-bottom: none !important;
}

.submit-bt {
  background: #13497b !important;
  max-width: 200px !important;
  display: block !important;
  width: 100% !important;
  margin: 0 auto;
  height: 45px;
  color: #fff !important;
}

.details-modal input {
  border-color: #dadada !important;
  height: 53px;
  box-shadow: none !important;
}

.details-modal .modal-dialog {
  max-width: 420px;
}

.details-modal .modal-body {
  padding: 10px 40px 30px;
}

.details-modal .modal-header {
  padding: 20px 30px 20px 40px;
}

.banner-botton-content {
  background: #ffffff2e;
  padding: 30px 90px 30px 30px;
  max-width: -moz-fit-content;
  max-width: fit-content;
}

.banner-botton-content h2 {
  font-size: 35px;
  color: #fff;
  margin: 25px 0;
}

hr:not([size]) {
  color: #1d1d1d69 !important;
}

div#appleid-signin {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
}

.interested-btn {
  border: 1px solid #29d370 !important;
  color: #29d370 !important;
  width: 150px;
  height: 45px;
  text-transform: uppercase;
  margin-right: 10px;
  font-weight: 500;
  margin-top: 10px;
}

.already-interested {
  border: 1px solid #29d370 !important;
  background-color: #29d370 !important;
  color: #fff !important;
  min-width: 150px;
  height: 45px;
  text-transform: uppercase;
  margin-right: 10px;
  font-weight: 500;
  margin-top: 10px;
}

.add-favorite-btn {
  border: 1px solid #e83f67 !important;
  color: #e83f67 !important;
  min-width: 150px;
  height: 45px;
  text-transform: uppercase;
  font-weight: 500;
  margin-top: 10px;
}

.tab-content .btn.sign-btn:focus {
  background: #11dfed;
}

.property-button-selects .auction-select {
  width: unset !important;
  max-width: unset;
}

.fex_row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.rowtext p {
  color: #8e8e8e;
  margin-bottom: 0;
}

.pac-container {
  z-index: 99999;
}

.remove-favorite-btn {
  border: 1px solid #e83f67 !important;
  color: #ffff !important;
  background-color: #e83f67 !important;
  min-width: 150px;
  height: 45px;
  text-transform: uppercase;
  font-weight: 500;
  margin-top: 10px;
}

.house-details-left-side h2 {
  font-size: 40px;
  font-weight: 700;
  cursor: pointer;
}

.house-details-left-side ul {
  padding: 0;
  display: flex;
  align-items: center;
}

.house-details-left-side li {
  margin-right: 30px;
  color: #8e8e8e;
}

.interested-btn:hover {
  background: #29d370 !important;
  color: #fff !important;
}

.add-favorite-btn:hover {
  background: #e83f67 !important;
  color: #fff !important;
}

.color-green {
  color: #53d769 !important;
}

.color-green i {
  margin-right: 5px;
}

.auction-start-time {
  display: flex;
  justify-content: space-between;
}

.timer-box {
  border-image-source: linear-gradient(to top, #13497b, #11dfed);
  border-image-slice: 1;
  border-width: 2px;
  border-style: solid;
  width: 87px;
  display: flex;
  height: 87px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.timer-box h3 {
  margin: 0;
  font-weight: 700;
  color: #11dfed;
  font-size: 30px;
}

.price-fee-box {
  display: flex;
  justify-content: space-around;
}

.__react_component_tooltip.show {
  max-width: 300px !important;
}

.auction-timer-box {
  border: 1px solid #e8e8e8;
  border-radius: 5px;
  padding: 20px;
  margin-top: 20px;
}

.timer-box p {
  font-size: 20px;
  line-height: 22px;
  margin-bottom: 0;
}
.mortgage-content p::-webkit-scrollbar {
  width: 4px;
}
.mortgage-content p::-webkit-scrollbar-track {
  background: #b0b0b0;
  border-radius: 9px;
}

.mortgage-content p::-webkit-scrollbar-thumb {
  background-color: #717174;
  /* border: 1px solid #ffffff;  */
  border-radius: 14px;
}
/* .bid-page-area {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
} */
.bid-page-content {
  display: flex;
  justify-content: space-between;
}
.bid-page-content p:first-child {
  color: #303030;
}
.mortgage-content p {
  color: #000;
  max-height: 150px;
  overflow: auto;
  text-align: justify;
  padding-right: 18px;
}

.text-black p {
  color: #000;
}

.bids-form h5 {
  font-size: 30px;
  font-weight: 700;
  color: #11dfed;
}

.bids-form h5 span {
  font-size: 20px;
}

.card-input {
  padding-left: 52px !important;
}

.master-icon {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translate(0%, -45%);
}

.red-text {
  color: #e83f67;
  font-size: 18px;
  text-decoration: underline;
  margin-top: 10px;
  cursor: pointer;
  margin-bottom: 0;
}

.favorite-proprty-one {
  padding: 20px;
  background: #ffffff;
  border: 1px solid rgba(29, 29, 29, 0.1);
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.03);
  border-radius: 5px;
  height: 100%;
}

.favorite-property-image {
  width: 100%;
  height: 230px;
  overflow: hidden;
}

.service-one h6 span {
  font-weight: 400;
  color: #8e8e8e;
  font-size: 14px;
  margin-left: 5px;
}

.house-details-left-side li img {
  margin-right: 5px;
}

.favorite-property-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
}

.favorite-proprty-one h4 {
  font-weight: 600;
  margin: 12px 0 5px;
  font-size: 22px;
}

.favorite-proprty-one h5 {
  font-weight: 700;
  margin-bottom: 15px;
  font-size: 25px;
}

.favorite-proprty-one h5 small {
  font-size: 16px;
}

.blue-text i {
  font-size: 12px;
}

.favorite-proprty-one .blue-text {
  margin-bottom: 0;
  text-decoration: underline;
}

.base-enrty-amount {
  display: flex;
  justify-content: space-around;
}

.capalites-left-side h2 {
  font-weight: 600;
  font-size: 40px;
}

.capalites-right-side h2 {
  font-weight: 700;
}

.capalites-right-side h6 {
  font-size: 20px;
  color: #1d1d1d;
  font-weight: 600;
}

.capalites-right-side p {
  margin-bottom: 0;
}

.capalites-right-side {
  display: flex;
  flex-direction: column;
  padding: 30px 20px;
  background: #fff;
  border-radius: 24px;
  text-align: center;
  height: 250px;
  max-height: 250px;
}

.residential-property-sales {
  display: flex;
  justify-content: space-evenly;
  margin-top: 60px;
}

.vector-img {
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
}

.capalites-area {
  padding: 70px 0;
  position: relative;
}

.gradient-border {
  background: linear-gradient(to top, #13497b, #11dfed);
  border-radius: 26px;
  padding: 2px;
}

.app-area {
  background: url("../images/app-bg.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 50px 0;
}

.platform-section {
  padding-top: 40px !important;
}

.our-property-area h2 {
  font-weight: 600;
  font-size: 40px;
  margin-bottom: 15px;
}

.our-property-area {
  background-image: url("../images/bg-2.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 100px 0 50px;
}

.seller-bottom-box h3 {
  font-size: 40px;
  font-weight: 700;
  margin-bottom: 30px;
  color: #fff;
}

.home-property .properties-slider li img {
  height: auto;
}

.all-feattures-area {
  padding: 120px 0 30px 0;
  position: relative;
}

.home-property {
  background-image: url("../images/bg-property.png");
  background-image: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 60px 0;
}

.navbar-collapse a.active {
  color: #11dfed !important;
}

.navbar-collapse a.active::after {
  content: "";
  background: #11dfed;
  height: 3px;
  width: 100%;
  position: absolute;
  left: 0;
  bottom: -25px;
}

small {
  font-weight: 400;
}

.home-property li {
  padding: 0 8px;
}
.home-property
  button.react-multiple-carousel__arrow.react-multiple-carousel__arrow--right {
  position: absolute;
  bottom: 0 !important;
  right: 45% !important;
  background: none !important;
  border: 2px solid #fff;
}

.home-property .react-multiple-carousel__arrow--right::before {
  content: "\21FE" !important;
  color: #fff !important;
}

.home-property .react-multiple-carousel__arrow--left::before {
  content: "\21FD" !important;
  color: #fff !important;
}

.home-property .react-multiple-carousel__arrow--left {
  position: absolute;
  bottom: 0 !important;
  left: 45% !important;
  background: none !important;
  border: 2px solid #fff;
}

.home-property .react-multi-carousel-list {
  padding-bottom: 80px;
}

.home-property h2 {
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 50px;
}

.blue-text.white-text {
  font-size: 16px;
  color: #fff;
  font-weight: 300;
}

.feature-one h5 {
  font-weight: 600;
  margin: 20px 0;
}

.right-vector-copy {
  bottom: -180px !important;
  top: auto !important;
  opacity: 0.3;
}

.left-vector-copy {
  top: -100px !important;
  opacity: 0.3;
}

.right-vector {
  position: absolute;
  right: 0;
  top: 0;
}

.left-vector {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0.3;
}

.seller-bottom-box {
  background: #11dfed;
  padding: 40px 0;
  border-radius: 5px;
  position: relative;
  bottom: -75px;
  text-align: center;
  display: none;
}

.team-images img {
  width: 196px;
  min-width: 196px;
  object-fit: contain;
  /* border: 1px solid #fff; */
  border-radius: 50%;
}

.team-images {
  text-align: center;
}

.team-images h6 {
  margin-top: 20px;
}

.form-side {
  background: #13497b;
  padding: 50px 30px;
  border-radius: 5px;
}

.form-side label {
  color: #fff;
  font-weight: 300;
  margin-top: 10px;
}

.position_btn {
  position: relative;
}

.position_btn #g-signin2 {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.form-side input {
  height: 61px;
  background: no-repeat !important;
  border: 1px solid #fff;
  color: #fff !important;
}

.form-side input::placeholder {
  color: rgba(255, 255, 255, 0.795);
}

.form-side textarea {
  background: no-repeat !important;
  border: 1px solid #fff;
  color: #fff !important;
}

.contact-form {
  margin: 60px 0;
}

.contact-submit {
  width: 200px;
  background: #11dfed !important;
  height: 65px;
  font-size: 18px;
  color: #fff !important;
}

.form-side h2 {
  font-size: 40px;
  font-weight: 500;
  margin: 10px 0;
  color: #fff;
}

.app-store-box-left-side h2 {
  font-size: 40px;
  font-weight: 600;
  margin-bottom: 25px;
}

.app-store-box-left-side p {
  margin-bottom: 15px;
}

.app-store-box {
  background-image: url(../images/bg-2.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 70px 0;
}

p.message-alert {
  margin-bottom: 0;
  margin-top: 10px;
}

.faq-area-box {
  max-width: 900px;
  margin: 80px auto 40px;
  padding: 0 20px;
}

.faq-area-box .accordion-button:not(.collapsed) {
  color: #000000;
  background-color: #ffffff;
  box-shadow: none !important;
  border-bottom: 1px solid #1d1d1d1a !important;
}

h2.accordion-header button {
  font-size: 19px !important;
  font-weight: 500;
}

.faq-area-box .accordion-body {
  color: #1d1d1d94;
}

.accordion-button:not(.collapsed)::after {
  background-image: url(../images/minus.png) !important;
  transform: rotate(-180deg);
  position: absolute;
  left: 20px;
}

.accordion-button::after {
  background-image: url(../images/plus.png) !important;
  position: absolute;
  left: 20px;
}

.accordion-item {
  margin: 10px 0;
}

.accordion-item:first-of-type .accordion-button {
  border: none !important;
}

.faq-area-box .accordion-button {
  color: #000000;
  background-color: #ffffff;
  box-shadow: none !important;
  padding: 30px 60px;
  border-top: 1px solid #1d1d1d1a !important;
}

.privay-content {
  border: 1px solid #1d1d1d1f;
  border-radius: 5px;
  padding: 0 30px 30px 30px;
  margin: 50px 0 70px;
}

.privay-content h6 {
  font-size: 18px;
  margin-top: 30px;
  color: #13497b;
}

.privay-content ul li {
  list-style: disc;
  color: #8e9092;
  margin-bottom: 15px;
}

.user-profile {
  text-align: center;
  margin-bottom: 20px;
  position: relative;
}

.share-bt {
  background: #13497b;
  width: fit-content;
  padding: 5px 15px;
  border-radius: 35px;
  margin: 0 auto;
  color: #fff;
  font-size: 14px;
  cursor: pointer;
}

button.password-btn p,
button.subscription-btn p {
  margin-bottom: 0;
  color: #13497b;
  text-transform: uppercase;
  font-size: 18px;
  font-weight: 500;
}

button.password-btn.change-plan-btn {
  margin-top: 0 !important;
}

#centerButtonDiv {
  position: absolute;
  right: 15px !important;
  bottom: 0px;
}

button.password-btn,
button.subscription-btn {
  border: 2px solid #13497b;
  padding: 13px;
  border-radius: 5px;
  margin: 40px 0 15px 0;
  background: transparent;
}

.user_update-profile {
  margin: 0 auto;
  text-align: center;
  width: 150px;
  position: relative;
  padding-top: 20px;
}

.user_update-profile img,
.user-profile img {
  height: 150px;
  width: 150px;
  object-fit: cover;
  border-radius: 50%;
}

.edit-profile i {
  position: absolute;
  right: 5px;
  background: #11dfed;
  bottom: 5px;
  color: #fff;
  width: 37px;
  height: 37px;
  text-align: center;
  justify-content: center;
  display: flex;
  font-size: 18px;
  line-height: 36px;
  border-radius: 50%;
}
.tab-area-sale ul.nav.nav-tabs {
  border: none;
}
.agent-info {
  display: flex;
  align-items: center;
  border: 1px solid rgba(29, 29, 29, 0.1);
  border-radius: 5px;
  padding: 15px;
  height: 100%;
}

.agent-image img {
  width: 88px;
  min-width: 88px;
  height: 88px;
  object-fit: cover;
  border-radius: 50%;
}
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  border: none !important;
  background: #13497b !important;
  color: #fff !important;
  border-radius: 5px;
  padding: 10px 40px;
  font-weight: 500;
  border: 1px solid #13497b !important;
}
.tab-area-sale ul.mb-3.nav.nav-tabs {
  border: none !important;
}
.tab-area-sale li.nav-item {
  margin-right: 15px;
}
.nav-tabs .nav-link {
  border: 1px solid #13497b !important;
  color: #13497b !important;
  border-radius: 5px;
  font-weight: 500;
  padding: 10px 40px;
}
.agent-description {
  margin-left: 15px;
}
.company-logo-image {
  position: absolute;
  right: 140px;
  bottom: -5px;
}
.company-logo-image img {
  max-width: 56px;
  max-height: 56px;
}
.agents-section {
  padding: 40px 0;
}

.home-banner {
  padding: 80px 0;
}

.home-banner h5 {
  font-weight: 400;
  text-transform: uppercase;
}

.home-banner h1 {
  font-size: 54px;
  font-weight: 600;
  margin: 20px 0;
}

.search-bt-box span.input-group-text {
  background: none;
  border: none;
  position: absolute;
  top: 5px;
  z-index: 1;
}

.search-bt-home {
  z-index: 1 !important;
  right: 0;
  cursor: pointer;
  background: #13497b !important;
  height: 50px;
  font-size: 18px;
  color: #fff !important;
  top: 0 !important;
}

.all-feattures-area h2 {
  font-size: 40px;
  font-weight: 600;
  margin-bottom: 50px;
}

.about-section {
  position: relative;
  padding: 60px 0;
}

.app-store-box-left-side .blue-text {
  text-decoration: underline;
}

.search-bt-box input {
  border-radius: 5px !important;
  height: 50px;
  padding: 0 0 0 50px;
  box-shadow: none !important;
  z-index: 0 !important;
  border-color: #1d1d1d29 !important;
}

.search-bt-box input::placeholder {
  color: #1d1d1d29 !important;
}

.pize-auction {
  display: flex;
  justify-content: space-between;
}

.notification-count {
  background: #11dfed;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  font-size: 11px;
  justify-content: center;
  color: #fff;
  position: absolute;
  top: 1px;
  right: -1px;
}

.notification-drop button {
  border: none !important;
  padding: 0;
}

.notification-drop .dropdown-menu[data-bs-popper] {
  left: inherit;
  right: -10px;
  top: 33px;
}

.notification-drop .notification_body:after {
  content: "";
  background: white;
  width: 15px;
  height: 15px;
  position: absolute;
  top: -8px;
  right: 9px;
  transform: rotate(45deg);
  box-shadow: -1px -2px 2px #cbcbcb;
}

.notification-drop .dropdown-menu.show {
  border: none;
  box-shadow: 2px 2px 9px #e5e5e5;
}

p.price1 {
  margin: 0;
  font-size: 12px;
  line-height: 14px;
  color: #000;
  text-transform: capitalize;
  font-weight: 500;
}

.notification-heading p {
  margin: 0;
  text-transform: capitalize;
  font-weight: 500;
  font-size: 14px;
  color: #11dfed;
  text-decoration: underline;
  cursor: pointer;
}

.notification-heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 6px 10px 5px;
  border-bottom: 1px solid #e1e1e1;
}

.notification-new {
  padding: 9px 0 2px;
}

.notification-heading h6 {
  margin: 0;
  font-size: 14px;
}

.content {
  border-bottom: 1px solid #dfdfdf;
}

.content p {
  padding: 0 10px;
}

p.timeng1 {
  font-size: 13px;
  margin-bottom: 0;
}

.main-para {
  font-size: 16px;
  font-weight: 700;
  color: #13497b;
}

.notification_body {
  width: 250px;
  padding: 0px;
}

.notification-drop .dropdown-toggle::after {
  display: none;
}

.search-input img {
  position: absolute;
  right: 0;
  top: 12px;
}

.toast-header {
  background: none;
  color: #13497b !important;
  border-bottom: none !important;
  padding: 10px 0 0 5px !important;
}

.toast-body {
  background: #fff !important;
  border-radius: 0 0 10px 10px !important;
  padding: 0 0 7px 13px !important;
  font-size: 13px !important;
}

.search-input {
  position: relative;
}

.search-input img {
  position: absolute;
  right: 20px;
  top: 12px;
}

.search-input input {
  height: 45px;
  border-radius: 5px;
  border: 1px solid #dadada;
}

.search-input input {
  height: 45px;
  border-radius: 5px;
  border: 1px solid #dadada;
}

.properties-tab-search-area {
  background: white;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0px 21px 33px rgba(0, 0, 0, 0.05);
  position: relative;
  top: -40px;
  max-width: 992px;
  margin: 0 auto;
}

.sale-bt {
  background: #13497b !important;
  color: #fff !important;
  width: 170px;
  height: 45px;
}

.share-property-modal h3 {
  font-size: 30px;
  color: #13497b;
}

.share-property-modal i {
  background-color: #13497b !important;
  padding: 8px 20px 13px;
  color: #fff;
  border-radius: 0 5px 5px 0;
}

.share-property-modal input {
  height: 40px;
  width: 80%;
  padding: 0 8px;
  border-radius: 8px 0 0 8px;
  border: 1px solid #e3e3e3;
}

.share-property-modal .ok-bt {
  background: #13497b;
  border: none;
  width: 120px;
  height: 40px;
}

.cross-sign {
  font-size: 16px;
  cursor: pointer;
}

.x-cross {
  width: 32px !important;
}

.pagination-property ul li {
  width: 35px;
  height: 35px;
  text-align: center;
  display: flex;
  justify-content: center;
  margin: 0 2px;
}

.new-padding {
  padding: 6px 20px;
}

.pagination li.DisableClass a {
  border-color: #bdbdbd !important;
  color: #dbdbdb !important;
}

.pagination li a {
  border-color: #13497b !important;
  color: #13497b !important;
}

.prev-pagi.page-item a {
  background: #11dfed !important;
  border: none !important;
  padding: 7px;
  color: white !important;
  font-size: 40px;
  height: 39px;
  line-height: 20px;
}

.upload-img input {
  width: 140px;
  height: 140px;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  border-radius: 50% !important;
  z-index: 1;
  opacity: 0;
}

.select-check {
  background: #11dfed;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  text-align: center;
  line-height: 40px;
  font-size: 19px;
  color: #fff;
  position: absolute;
  right: -20px;
  top: -20px;
  display: none;
}

.selected .select-check {
  display: block;
}

.form-check-input:checked[type="radio"] {
  background-image: url("../images/dot.png") !important;
  box-shadow: none !important;
  cursor: pointer !important;
  border-color: #11dfed !important;
  background-size: auto;
  background-color: white;
}

.form-check-input[type="radio"] {
  box-shadow: none !important;
  cursor: pointer !important;
  border-color: #143656;
}

.form-check-input[type="checkbox"] {
  box-shadow: none !important;
  cursor: pointer !important;
  border-color: #143656;
}

.form-check-input:checked[type="checkbox"] {
  background-color: #11dfed !important;
  border-color: #11dfed !important;
}

.upload-img {
  position: relative;
}

.form-check.form-check-inline label {
  cursor: pointer !important;
  font-size: 14px;
}

.add-listing-page .form-check.form-check-inline {
  width: 33.33%;
  margin: 0;
}

.listing-submit {
  display: block !important;
  max-width: 300px !important;
  margin: 0 auto !important;
}

.upload-img img {
  width: 140px;
  gap: 140px;
  border-radius: 50%;
  object-fit: cover;
  height: 140px;
}

.picture-one-new {
  width: 80px;
  margin-right: 15px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.tab-content .form-check.form-check-inline {
  margin: 3px 0 6px !important;
}

.add_property .my-3 {
  margin-top: 0.7rem !important;
  margin-bottom: 0.7rem !important;
}

.add_property .mb-3 {
  margin-bottom: 0.7rem !important;
}

.nav-user-profile a {
  display: block;
  width: 100%;
  padding: 0.25rem 1rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

.position_relative {
  position: relative;
  height: 100%;
}

.favorite-proprty-one p {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
}

.nofound {
  font-size: 24px;
  height: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
}

.auction-bt.active {
  background: #13497b !important;
  color: #fff !important;
}

.search-input input {
  padding-right: 34px;
}

.regular-auction-buttons button {
  width: 200px;
}

.property-button-selects {
  display: flex;
  align-items: center;
}

.tab-area-sale.ab-area-sale1 .sale-bt {
  background: transparent !important;
  border: 1.3px solid #13497b !important;
  color: #13497b !important;
  width: unset;
  padding: 10px 17px;
}

.tab-area-sale.ab-area-sale1 {
  display: flex;
  justify-content: space-between;
}

.tab-area-sale.ab-area-sale1 .active.sale-bt {
  background: #13497b !important;
  color: #fff !important;
  width: unset;
  /* height: 45px; */
}

.auc-bt.active {
  background: #11dfed !important;
  color: #fff !important;
  margin: 0 5px;
}

.auc-bt {
  border: 1px solid #11dfed !important;
  color: #11dfed !important;
  margin: 0 5px;
}

.auction-select {
  max-width: 150px;
  box-shadow: none !important;
  cursor: pointer;
  border: 1px solid #ced4da !important;
}

.regular-auction-buttons {
  width: 490px;
}

.nav-user-profile a:hover {
  color: #13497b;
}

.uploaded-pictures i {
  position: absolute;
  background: red;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  text-align: center;
  line-height: 18px;
  font-size: 14px;
  color: #fff;
  padding: 0 4px;
  right: -5px;
  top: -5px;
  cursor: pointer;
}

.uploaded-pictures {
  display: flex;
  align-items: center;
}

.uploaded-pictures img {
  width: 80px;
  height: 80px;
  border-radius: 10px;
  object-fit: cover;
  border: 1px solid #f5f5f5;
}

.main-bath-check .form-check {
  margin-bottom: 12px !important;
}

.add-listing-page .login-form {
  padding: 25px;
}

.pagination-property ul {
  justify-content: center;
  margin-top: -30px;
}

.add-listing-page label.form-label {
  font-weight: 400;
  margin-bottom: 0px !important;
}

.listing-tab-top button {
  width: 200px;
  border: 1px solid #13497b !important;
  color: #13497b !important;
  border-radius: 5px !important;
  margin: 0 5px;
  text-transform: uppercase;
  font-weight: 500;
}

.listing-tab-top button.active {
  background: #13497b !important;
  color: #fff !important;
  margin-top: 10px;
}

.listing-tab-top {
  justify-content: center;
  padding: 30px 0;
}

.listing-tab-top {
  justify-content: center;
}

.listing-tab-top button {
  width: 200px;
  border: 1px solid #13497b !important;
  color: #13497b;
  margin-top: 10px;
  white-space: nowrap;
}

.next-pagi a {
  font-size: 40px;
  height: 39px;
  line-height: 20px;
}

.pagination-property .page-item.active .page-link {
  background: none !important;
  border: 1px solid #13497b !important;
  color: #13497b !important;
}

.listing-btn {
  margin-left: 10px;
  border: 1px solid #13497b !important;
  color: #13497b !important;
  width: 150px;
  height: 45px;
  text-transform: uppercase;
  font-weight: 500;
  margin-top: 10px;
}

.pagination li a {
  text-decoration: none;
  font-size: 16px;
  padding: 0;
  width: 30px;
  align-items: center;
  display: flex;
  line-height: 29px;
  height: 30px;
  justify-content: center;
}

li.activepagination a {
  background: #11dfed;
  border-color: #11dfed !important;
  color: #fff !important;
}

.pagination-property a {
  border-radius: 5px;
  color: red;
  border: 1px solid rgba(29, 29, 29, 0.5) !important;
  color: #000;
  font-size: 20px;
  padding: 20px 20px;
  display: flex;
  align-items: center;
}

.auction-bt {
  width: 170px;
  border: 2px solid #13497b !important;
  height: 45px;
  color: #13497b !important;
  font-weight: 600 !important;
  margin-left: 10px;
}

.bg-cls {
  background: #00000094;
  width: 100%;
  height: 100vh;
  position: fixed;
  z-index: 1024;
}

.search-input img {
  cursor: pointer;
}

.pac-target-input::-webkit-search-cancel-button {
  position: relative;
  cursor: pointer;
}

.tab-area-sale {
  text-align: end;
}

.properties-tab-search-area .search-input input {
  padding-right: 70px;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.search-input img.position_img {
  right: 46px;
  top: 16px;
  width: 13px;
}

.circle-loader {
  position: relative;
}

.circle-loader div {
  height: 10px;
  width: 10px;
  background-color: #11dfed;
  border-radius: 50%;
  position: absolute;
  -webkit-animation: 1.3s opaque ease-in-out infinite both;
  animation: 1.3s opaque ease-in-out infinite both;
}

.circle-loader > div:nth-child(1) {
  top: -25px;
  left: 0;
}

.circle-loader > div:nth-child(2) {
  top: -17px;
  left: 17px;
  -webkit-animation-delay: 0.15s;
  animation-delay: 0.15s;
}

.circle-loader > div:nth-child(3) {
  top: 0;
  left: 25px;
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}

.circle-loader > div:nth-child(4) {
  top: 17px;
  left: 17px;
  -webkit-animation-delay: 0.45s;
  animation-delay: 0.45s;
}
.bid-page-area {
  margin-bottom: 22px;
}
.circle-loader > div:nth-child(5) {
  top: 25px;
  left: 0;
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
}

.circle-loader > div:nth-child(6) {
  top: 17px;
  left: -17px;
  -webkit-animation-delay: 0.75s;
  animation-delay: 0.75s;
}

.circle-loader > div:nth-child(7) {
  top: 0;
  left: -25px;
  -webkit-animation-delay: 0.9s;
  animation-delay: 0.9s;
}

.circle-loader > div:nth-child(8) {
  top: -17px;
  left: -17px;
  -webkit-animation-delay: 1.05s;
  animation-delay: 1.05s;
}

.loader-box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@-webkit-keyframes opaque {
  0%,
  40%,
  100% {
    opacity: 0.1;
  }

  40% {
    opacity: 1;
  }
}

@keyframes opaque {
  0%,
  40%,
  100% {
    opacity: 0.1;
  }

  40% {
    opacity: 1;
  }
}

.border-line {
  border-top: 1px solid rgba(29, 29, 29, 0.1);
  border-bottom: 1px solid rgba(29, 29, 29, 0.1);
  padding: 18px 0;
}

/* 20-9 */
.app-area-heading {
  text-align: center;
  margin-bottom: 55px;
}

.faq-area-box .accordion-body ul li {
  list-style: disc;
}

/** start responsive **/
@media (max-width: 1400px) {
  .house-details-left-side .service-one img {
    margin-right: 15px;
    width: 55px;
  }
  header .navbar-light .navbar-nav .nav-link {
    font-size: 14px;
  }

  .service-one h6 span {
    font-size: 13px;
  }

  .service-one h6 {
    font-size: 15px;
  }

  .timer-box {
    width: 70px;
    height: 77px;
  }

  .timer-box h3 {
    font-size: 25px;
  }

  .timer-box p {
    font-size: 16px;
  }

  .copyright-area li p {
    font-size: 14px;
  }
}

@media screen and (max-width: 1299px) {
  header .navbar-light .navbar-nav .nav-link {
    margin: 0 8px;
  }

  .navbar-collapse a.active::after {
    bottom: -4px;
  }

  .navbar-toggler:focus {
    box-shadow: none !important;
  }
}

@media screen and (max-width: 1199px) {
  .base-price-box.base-enrty-amount {
    font-size: 13px;
  }
  header .navbar-light .navbar-nav .nav-link {
    margin-bottom: 15px;
  }

  .base-price-box h5 {
    font-size: 20px;
  }

  .favorite-proprty-one .blue-text {
    font-size: 15px;
  }

  .favorite-proprty-one p.red-text {
    font-size: 15px;
  }

  .favorite-proprty-one .service-one img {
    width: 17px;
    margin-right: 6px;
  }

  .favorite-proprty-one h4 {
    font-size: 19px;
  }

  .timer-box {
    width: 57px;
    height: 69px;
  }

  .timer-box h3 {
    font-size: 21px;
  }

  .timer-box p {
    font-size: 14px;
  }

  .table-responsive td {
    font-size: 14px;
  }

  .information-table tr td:first-child {
    font-size: 13px;
  }

  .service-property {
    flex-wrap: wrap;
  }

  .capalites-right-side h6 {
    font-size: 19px;
  }

  .feature-one h5 {
    font-size: 17px;
  }

  header .navbar-light .navbar-nav .nav-link {
    font-size: 14px;
    margin-left: 8px;
  }

  footer p {
    font-size: 14px;
  }

  .btn.fill-btn.margin-left {
    margin-left: 17px;
  }

  .navbar-brand img {
    width: 90px;
  }

  .copyright-area li p {
    font-size: 13px;
    margin-left: 0;
  }

  .copyright-area ul li {
    margin-left: 16px;
  }

  /* .favorite-property-image {
    height: unset;
  } */

  .favorite-proprty-one p {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

@media screen and (max-width: 991px) {
  .agent-info {
    padding: 10px;
}

.properties-tab-search-area {
  padding: 33px 10px 30px 85px;
}
  .navbar-collapse a.active::after {
    bottom: -7px;
  }

  .agent-image img {
    width: 70px;
    min-width: 70px;
    height: 70px;
  }

  .favorite-proprty-one h5 {
    font-size: 17px;
  }

  .favorite-proprty-one {
    padding: 10px;
  }

  .favorite-proprty-one h4 {
    font-size: 16px;
  }

  .favorite-proprty-one p,
  .favorite-proprty-one p.red-text {
    font-size: 14px;
  }

  .auction-start-time {
    justify-content: center;
  }

  .timer-box {
    width: 67px;
    height: 67px;
    margin: 0 5px;
  }

  .contact-form .form-side input {
    height: 52px;
  }

  .home-property .react-multiple-carousel__arrow--left {
    left: 42% !important;
  }

  .home-property
    button.react-multiple-carousel__arrow.react-multiple-carousel__arrow--right {
    right: 42% !important;
  }

  .house-details-left-side {
    padding-right: 0;
  }

  .tab-area-sale {
    text-align: center;
  }

  .login-form {
    padding: 30px 30px 0 30px;
  }

  .btn.outline-btn {
    margin-top: 10px;
  }

  .copyright-area ul {
    text-align: center;
    margin-top: 6px;
  }

  .copyright-area {
    padding: 10px 0;
  }

  .login-box-area {
    padding: 60px 0;
  }

  .navbar-light .navbar-toggler:focus {
    box-shadow: none;
  }

  .home-banner h1,
  .app-store-box-left-side h2 {
    font-size: 36px;
  }

  .home-banner h1 br,
  .home-banner p br,
  .app-store-box-left-side h2 br {
    display: none;
  }

  .service-one {
    margin-bottom: 15px;
  }
}

@media screen and (max-width: 767px) {
  td.text-spacing {
    font-size: 10px;
  }

  .property-button-selects .btn,
  .nav-tabs .nav-link {
    padding: 10px 20px;
  }

  .property-button-selects .auction-select {
    font-size: 14px;
  }

  .favorite-proprty-one .service-property .service-one {
    width: unset;
  }

  .service-property .service-one {
    width: 50%;
  }
  .properties-tab-search-area {
    padding: 20px 30px 20px 30px;
  }
  .auction-bt {
    width: 151px;
    font-size: 15px !important;
    margin-left: 4px;
  }

  .all-feattures-area {
    padding: 60px 0;
  }

  .all-feattures-area h2,
  .app-store-box-left-side h2,
  .form-side h2,
  .seller-bottom-box h3 {
    font-size: 29px;
  }

  .favorite-proprty-one {
    padding: 10px;
  }

  .favorite-proprty-one h4 {
    font-size: 18px;
  }

  .favorite-proprty-one p {
    font-size: 13px;
  }

  .favorite-proprty-one h5 {
    font-size: 18px;
  }

  .home-property .properties-slider li .service-property img {
    width: 16px;
    margin-right: 6px;
  }

  .home-property .react-multiple-carousel__arrow--left {
    left: 40% !important;
  }

  .home-property
    button.react-multiple-carousel__arrow.react-multiple-carousel__arrow--right {
    right: 40% !important;
  }

  .about-section .mobile-img {
    text-align: center;
    margin-bottom: 20px;
  }

  .home-banner .row {
    flex-direction: column-reverse;
  }

  .homehero-img {
    margin-bottom: 40px;
  }

  .home-banner {
    padding: 80px 0 0 0;
  }

  .contact-image {
    display: none;
  }

  .seller-bottom-box {
    padding: 40px 20px;
  }

  .seller-bottom-box h3 {
    font-size: 32px;
  }

  .our-property-area h2 {
    font-size: 35px;
  }

  .basic-plan-box {
    margin: 10px auto;
  }
}

@media screen and (max-width: 575px) {
  .property-button-selects {
    flex-direction: column;
  }
  .bid-page-content p:first-child {
    color: #303030;
    font-size: 13px;
}
.bid-page-content p {
  font-size: 13px;
}
  .bid-page-content {
    flex-direction: column;
    margin-bottom: 10px;
  }
  .bid-page-content p{
    margin-bottom: 3px;
  }
  .tab-area-sale ul.nav.nav-tabs {
      display: flex;
      justify-content: center;
  }
  .properties-tab-search-area {
    padding: 20px 10px 20px 15px;
  }
  .tab-area-sale li.nav-item {
    margin-right: 9px;
  }
  .btn.auc-bt,
  .property-button-selects .auction-select {
    font-size: 15px;
    width: 230px !important;
    margin-bottom: 10px;
  }

  .plans-description li {
    font-size: 14px;
  }

  .basic-payment img {
    width: 80px;
  }

  .agent-info {
    padding: 18px 12px;
  }

  .agent-description p {
    font-size: 14px;
    margin-bottom: 0;
  }

  .agent-image img {
    width: 55px;
    min-width: 55px;
    height: 55px;
  }

  .faq-area-box .accordion-button {
    padding: 16px 14px 16px 57px;
  }

  h2.accordion-header button {
    font-size: 16px !important;
    font-weight: 500;
  }

  .banner-botton-content h2 br {
    display: none;
  }

  .favorite-proprty-one .service-property .service-one img {
    width: 21px;
  }

  .favorite-proprty-one .service-property .service-one {
    margin-right: 30px;
  }

  .favorite-proprty-one .service-property {
    justify-content: unset;
  }

  .house-details-left-side ul.flex_mob {
    align-items: start;
    flex-direction: column;
  }

  .house-details-left-side ul.flex_mob li {
    margin-bottom: 9px;
  }

  .house-details-left-side h2 {
    font-size: 25px;
  }

  .service-one img {
    width: 49px;
  }

  .properties-tab-search-area {
    padding: 20px 12px 20px 12px;
}
  .tab-area-sale {
    text-align: end;
    display: initial;
    justify-content: space-between !important;
  }

  .auction-bt {
    width: 32%;
    font-size: 14px !important;
    margin-left: 0;
  }

  .btn.auc-bt {
    font-size: 15px;
  }

  .all-feattures-area h2,
  .app-store-box-left-side h2,
  .form-side h2,
  .seller-bottom-box h3 {
    font-size: 23px !important;
  }

  .home-property .react-multiple-carousel__arrow--left {
    left: 37% !important;
  }

  .home-property
    button.react-multiple-carousel__arrow.react-multiple-carousel__arrow--right {
    right: 37% !important;
  }

  .tab-area-sale.ab-area-sale1 .sale-bt {
    width: 100%;
    margin: 4px 0;
  }

  .tab-area-sale.ab-area-sale1 {
    display: flex;
    justify-content: space-between;
    flex-direction: column-reverse;
  }

  .otp-area .otp input {
    width: 80px !important;
  }

  header {
    padding: 13px 20px;
  }

  .social-btn .btn img {
    width: 22px;
  }

  .social-btn .btn {
    font-size: 13px;
  }

  .login-form {
    padding: 30px 22px 0 22px;
  }

  .login-form h2 {
    font-size: 30px;
  }

  .footer-area h3 {
    font-size: 27px;
  }

  .flex-p {
    font-size: 13px;
  }

  .all-feattures-area h2 {
    font-size: 22px;
    padding: 0 20px;
  }

  .banner-botton-content h2 {
    font-size: 27px;
  }

  .blue-text.white-text {
    margin-top: 10px;
  }

  .home-property h2 {
    flex-direction: column;
    /* align-items: start; */
    /* justify-content: start; */
  }

  .banner-botton-content h2 br {
    display: none;
  }

  .heading-area-top h2 {
    font-size: 33px;
  }

  .home-banner h1 {
    font-size: 31px;
  }
}

@media screen and (max-width: 485px) {
  .properties-tab-search-area {
    padding: 20px 10px 20px 20px;
  }
  .mortgage-content p {
      font-size: 14px;
  }
  .tab-area-sale li.nav-item,
  .nav-tabs .nav-link {
    width: 80%;
    margin: 2px auto;
  }

  .app-area-heading {
    margin-bottom: 25px;
  }
  input.form-control.pac-target-input {
    margin-bottom: 10px;
  }

  .basic-payment img {
    width: 70px;
  }

  .favorite-proprty-one .service-property .service-one {
    margin-right: 20px;
  }

  .auction-bt {
    width: 230px;
  }

  .tab-area-sale {
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }

  .home-property .react-multiple-carousel__arrow--left {
    left: 33% !important;
  }

  .home-property
    button.react-multiple-carousel__arrow.react-multiple-carousel__arrow--right {
    right: 33% !important;
  }

  .input-group {
    display: block !important;
  }

  .search-bt-box input {
    width: 100% !important;
    border-radius: 4px 4px 0 0 !important;
  }

  .search-bt-home {
    position: unset !important;
    border-radius: 0 0 4px 4px !important;
    text-align: center !important;
    justify-content: center !important;
  }

  .capalites-left-side h2 br {
    display: none;
  }

  .skip-now {
    margin: 10px 5px;
  }

  .capalites-left-side h2 {
    font-size: 30px;
  }

  .otp-area .otp input {
    width: 60px !important;
  }

  .auction-bt {
    margin: 10px 0 0 0;
  }

  .footer-form .btn.fill-btn {
    position: unset;
    transform: unset;
    margin-top: 10px;
  }

  .footer-form {
    text-align: center;
  }

  .footer-color ul {
    flex-wrap: wrap;
  }

  .footer-form input {
    padding-right: 10px;
  }

  .social-btn {
    flex-direction: column;
    align-items: center;
  }

  .social-btn .btn {
    display: inline-block;
    width: unset;
    margin-bottom: 10px;
    min-width: 220px;
  }

  .social-btn .btn:last-child {
    margin-bottom: 0;
  }

  .forgot-area br {
    display: none;
  }

  .hero-banner p br {
    display: none;
  }

  .banner-botton-content {
    padding: 30px 30px 30px 30px;
  }

  .banner-botton-content h2 {
    font-size: 29px;
  }

  .our-property-area h2 {
    font-size: 30px;
  }

  /* .service-property {
    flex-direction: column;
  } */
  .heading-area-top h2 {
    font-size: 27spx;
  }

  .next-previous-tab p {
    font-size: 13px;
  }

  .next-previous-tab p img {
    width: 16px;
  }

  .person-profile {
    padding: 13px 10px;
  }
  .tab-area-sale ul.mb-3.nav.nav-tabs {
    flex-direction: column;
  }
  /* .nav-tabs .nav-item.show .nav-link,
  .nav-tabs .nav-link.active {
    padding: 10px 53px;
  } */
  /* .nav-tabs .nav-link {
    padding: 10px 40px;
  } */
  .tab-area-sale li.nav-item {
    margin-top: 8px;
  }
}

@media screen and (max-width: 375px) {
  .basic-payment img {
    width: 60px;
  }
  .tab-area-sale li.nav-item, .nav-tabs .nav-link {
    width: 100%;  
}

  .timer-box {
    width: 59px;
    height: 65px;
    margin: 0 2px;
  }

  .timer-box h3 {
    font-size: 20px;
  }

  .timer-box p {
    font-size: 12px;
  }

  .house-details-left-side ul {
    flex-direction: column;
    align-items: start;
  }

  .house-details-left-side ul li {
    margin-bottom: 9px;
  }

  .service-property {
    margin-top: 10px;
  }

  .service-one p {
    font-size: 14px;
  }

  .house-details h6 {
    font-size: 14px;
  }

  .service-property .service-one img {
    width: 32px;
  }

  .otp-area .otp input {
    width: 50px !important;
    padding: 10px 13px;
  }

  .capalites-left-side h2 {
    font-size: 30px;
  }

  .login-form h2 {
    font-size: 28px;
  }
}

.ok-bt {
  width: 100px;
  margin: 0 auto;
}
